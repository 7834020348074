<template>
  <v-data-table
    fixed-header
    height="250px"
    :headers="headers"
    :items="meteograms"
    no-data-text="No location configured yet."
    class="elevation-0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Configuration Meteogram</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-spacer />
        <delete
          v-if="meteograms.length > 0"
          id="Meteograms"
          :is-btn="true"
          @deleted="deleteAllItems()"
        />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Add location
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <div v-if="editedIndex === -1">
              <v-alert
                v-if="limitExceeded"
                dense
                text
                type="warning"
                class="mt-3 mx-5"
                icon="mdi-alert-outline"
                transition="scale-transition"
              >
                Too many locations. Please enter up to {{ LOCATION_LIMIT }}.
              </v-alert>

              <v-card-text class="d-flex justify-space-between mt-5">
                <v-divider />
                <p class="mx-3 mt-n2 text-caption">
                  upload a csv file
                </p>
                <v-divider />
              </v-card-text>

              <v-card-text>
                <v-file-input
                  v-model="selectedFile"
                  autofocus
                  outlined
                  label="Select CSV file"
                  accept=".csv"
                  placeholder="Uploading csv"
                  prepend-inner-icon="mdi-file-delimited-outline"
                  :disabled="limitExceeded"
                  truncate-length="50"
                  @change="handleFileUpload"
                />
              </v-card-text>

              <v-card-text class="d-flex justify-space-between mb-n5">
                <v-divider />
                <p class="mx-3 mt-n2 text-caption">
                  or enter coordinates manually
                </p>
                <v-divider />
              </v-card-text>
            </div>

            <v-card-text>
              <v-container>
                <v-form
                  v-model="valid"
                  lazy-validation
                  :disabled="limitExceeded"
                >
                  <v-row>
                    <v-text-field
                      v-model="editedItem.location_name"
                      label="Insert location name"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="editedItem.latitude"
                      label="Insert location latitude"
                      :rules="geo_rules"
                      :counter="20"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="editedItem.longitude"
                      label="Insert location longitude"
                      :rules="geo_rules"
                      :counter="20"
                    />
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="(!Object.keys(editedItem).includes('latitude') || !Object.keys(editedItem).includes('longitude')) || !valid"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <delete
        :id="item.username"
        @deleted="deleteItem(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { required, manageLenghtError, getRegExp } from '@/formRules.js';
import { mapActions } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  props: {
    meteograms: {
      type: Array,
      default: () => []
    },
    toDelete: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      LOCATION_LIMIT: 1000,
      dialog: false,
      dialogDelete: false,
      valid: true,
      headers: [
        {
          text: 'Location name',
          align: 'start',
          sortable: true,
          value: 'location_name'
        },
        { text: 'Latitude', value: 'latitude' },
        { text: 'Longitude', value: 'longitude' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      geo_rules: [...required, ...getRegExp('numbers_dot_only'), ...getRegExp('space_not_allowed'), ...manageLenghtError(20)],
      selectedFile: null,
      editedIndex: -1,
      editedItem: {}
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Location' : 'Edit Location';
    },
    limitExceeded() {
      return this.meteograms.length >= this.LOCATION_LIMIT && this.editedIndex === -1;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    ...mapActions(['storeToastMessage']),
    editItem(item) {
      this.editedIndex = this.meteograms.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      if (item.meteogram_id) {
        this.toDelete.push(item.meteogram_id);
      }

      this.editedIndex = this.meteograms.indexOf(item);
      this.meteograms.splice(this.editedIndex, 1);
      this.close();
    },
    deleteAllItems() {
      this.meteograms.forEach(item => {
        if (item.meteogram_id) {
          this.toDelete.push(item.meteogram_id);
        }
      });
      this.$emit('delete-all');
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.meteograms[this.editedIndex], this.editedItem);
      } else {
        this.meteograms.push(this.editedItem);
      }

      this.close();
    },
    handleFileUpload() {
      if (!this.selectedFile) {
        this.storeToastMessage({
          text: 'No file selected.',
          type: 'warning'
        });
      } else {
        const reader = new FileReader();

        reader.onload = (event) => {
          const csvData = event.target.result;
          this.parseCSVData(csvData);
        };

        reader.onerror = (event) => {
          this.storeToastMessage({
            text: 'An error occurred while reading the file.' + event,
            type: 'error'
          });
        };

        reader.readAsText(this.selectedFile);
        this.selectedFile = null;

        this.storeToastMessage({
          text: 'Meteogram points added.',
          type: 'success'
        });
      }
    },
    parseCSVData(data) {
      const rows = data.split('\n').map(e => e.replace('\r', ''));
      const headers = rows[0].split(',');
      const tableData = [];

      if (rows.length + this.meteograms.length >= this.LOCATION_LIMIT) {
        this.storeToastMessage({
          text: `Too many locations. Please enter up to ${this.LOCATION_LIMIT}.`,
          type: 'error'
        });

        return;
      }

      rows.forEach((row, i) => {
        if (i > 0) {
          const cols = row.split(',');

          if (cols.length === headers.length) {
            const rowData = {};
            cols.forEach((col, j) => rowData[headers[j]] = cols[j]);
            const exists = this.meteograms.find(e => e.latitude === rowData.latitude && e.longitude === rowData.longitude);

            if (!exists) {
              tableData.push(rowData);
            }
          }
        }
      });

      this.meteograms.push(...tableData);
      this.close();
    }
  }
};
</script>
